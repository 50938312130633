// 顶部导航
const navigation = {
  getNavigationList: '/api/v1/navigation/getNavigationList'
};

// 用户
const loginPage = {
  login: '/api/v1/user/login',
  shortMessageLogin: '/api/v1/user/shortMessageLogin',
  getCode: '/api/v1/user/getVerificationCode',
  register: '/api/v1/user/register',
  myActivityList: '/api/v1/user/myActivityList',
  changePassword: '/api/v1/user/changePassword',
  getUserAgreement: '/api/v1/enterprise/getUserAgreement',
  logout: '/api/v1/user/logout'
};

// 主营业务
const majorUrl = {
  getTypeList: '/api/v1/book/getTypeList',
  getBookList: '/api/v1/book/getBookList',
  getBookInfo: '/api/v1/book/getBookInfo',
  getJournalList: '/api/v1/journal/getJournalList',
  getDigitList: '/api/v1/digit/getDigitList',
  getDigitItemList: '/api/v1/digit/getDigitItemList',
  getActivityList: '/api/v1/activity/getActivityList',
  getActivityInfo: '/api/v1/activity/getActivityInfo'
};

// 首页
const indexUrl = {
  getBannerList: '/api/v1/banner/getBannerList',
  getLatestNewsList: '/api/v1/news/getLatestNewsList',
  getLatestNoticeList: '/api/v1/notice/getLatestNoticeList',
  getMainBusinessList: '/api/v1/navigation/getMainBusinessList',
  getBrandList: '/api/v1/brand/getBrandList',
  indexSearch: '/api/v1/search'
};

// 关于时代
const aboutShidaiUrl = {
  getEnterpriseInfo: '/api/v1/enterprise/getEnterpriseInfo',
  getHonorList: '/api/v1/honor/getHonorList'
};

// 咨询动态
const consultationUpdatesUrl = {
  getNewsList: '/api/v1/news/getNewsList',
  getNewsInfo: '/api/v1/news/getNewsInfo',
  getNoticeList: '/api/v1/notice/getNoticeList',
  getNoticeInfo: '/api/v1/notice/getNoticeInfo',
  downloadFile: '/api/v1/notice/downloadFile'
};

// 读者互动
const readerInteractionUrl = {
  getCouponList: '/api/v1/coupon/getCouponList',
  getActivityList: '/api/v1/readerActivity/getActivityList',
  getReadInfo: '/api/v1/readerActivity/getActivityInfo',
  enterActivity: '/api/v1/readerActivity/enterActivity',
  userFeedback: '/api/v1/userFeedback/submitUserFeedback'
};

export {
  navigation,
  loginPage,
  majorUrl,
  indexUrl,
  aboutShidaiUrl,
  consultationUpdatesUrl,
  readerInteractionUrl
};
