export default {
  mounted() {
    this.setContentHeight();
  },
  methods: {
    // 设置内容高度
    setContentHeight() {
      // clientHeight:可视区的高度；scrollHeight:滚动条的总高度
      const { scrollHeight } = document.documentElement || document.body;
      this.height = scrollHeight - 126 - 273;
    }
  }
};
