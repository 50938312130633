// 引入axios框架
import axios from '@/utils/ajax/request';

export default {
  /**
   * ajax的get请求
   * @param url 请求链接
   * @param data 数据
   * @param [config] 请求参数 默认为{}
   * @return {Promise<any>} 请求后台服务返回数据
   */
  get(url, data = {}, config = {}) {
    return axios.get(url, {
      params: data,
      ...config
    });
  },
  /**
   * ajax的 post 请求
   * @param url 请求链接
   * @param data 请求参数
   * @param [config] 请求参数 默认为{}
   * @return {Promise<any>} 请求后台服务返回数据,
   */
  post(url, data, config = {}) {
    return axios.post(url, data, config);
  },
  /**
   * ajax的 put 请求
   * @param url 请求链接
   * @param data 请求参数
   * @param [config] 请求参数 默认为{}
   * @return {Promise<any>} 请求后台服务返回数据
   */
  put(url, data, config = {}) {
    return axios.put(url, data, config);
  },
  /**
   * ajax的delete请求
   * @param url 请求链接
   * @param params 参数
   * @param [config] 请求参数 默认为{}
   * @return {Promise<any>} 请求后台服务返回数据
   */
  delete(url, params, config = {}) {
    return axios.delete(url, {
      ...config,
      params
    });
  }
};
