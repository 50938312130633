// 对sessionStorage进行操作
// eslint-disable-next-line consistent-return
const sessionHandle = (sign, name, data) => {
  if (sign === 'set') {
    sessionStorage.setItem(name, data);
  } else if (sign === 'get') {
    return sessionStorage.getItem(name);
  } else {
    sessionStorage.removeItem(name);
  }
};

export {
  sessionHandle
};
