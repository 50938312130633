import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/layout/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/index')
  },
  {
    path: '/',
    name: '首页',
    redirect: '/index',
    component: Layout,
    children: [
      {
        path: '/index',
        component: () => import('../views/home'),
        name: 'index',
        meta: {
          title: '首页',
        },
      },
      {
        path: '/companyProfile',
        component: () => import('@/components/CompanyProfile'),
        name: 'companyProfile',
        meta: {
          title: '公司简介',
        },
      },
      {
        path: '/qualificationsHonors',
        component: () => import('@/components/QualificationsHonors'),
        name: 'qualificationsHonors',
        meta: {
          title: '资质荣誉',
        },
      },
      {
        path: '/companyNews',
        component: () => import('@/components/CompanyNews'),
        name: 'companyNews',
        meta: {
          title: '公司新闻',
        },
      },
      {
        path: '/noticeAnnouncement',
        component: () => import('@/components/NoticeAnnouncement'),
        name: 'noticeAnnouncement',
        meta: {
          title: '通知公告',
        },
      },
      {
        path: '/majorBusiness',
        component: () => import('@/components/MajorBusiness'),
        name: 'majorBusiness',
        meta: {
          title: '图书出版',
        },
      },
      {
        path: '/underBrand',
        component: () => import('@/components/UnderBrand'),
        name: 'underBrand',
        meta: {
          title: '旗下品牌',
        },
      },
      {
        path: '/journalPublishing',
        component: () => import('@/views/major-business/JournalPublishing'),
        name: 'journalPublishing',
        meta: {
          title: '期刊出版',
        },
      },
      {
        path: '/digitalPublishing',
        component: () => import('@/views/major-business/DigitalPublishing'),
        name: 'digitalPublishing',
        meta: {
          title: '数字出版',
        },
      },
      {
        path: '/activityMarketing',
        component: () => import('@/views/major-business/ActivityMarketing'),
        name: 'activityMarketing',
        meta: {
          title: '活动营销',
        },
      },
      {
        path: '/readerInteraction',
        component: () => import('@/views/reader-interaction'),
        name: 'readerInteraction',
        meta: {
          title: '读者互动',
        },
      },
      {
        path: '/userCenter',
        component: () => import('@/views/user-center'),
        name: 'userCenter',
        meta: {
          title: '个人中心',
        },
      },
      {
        path: '/updatePassword',
        component: () => import('@/views/user-center'),
        name: 'updatePassword',
        meta: {
          title: '修改密码',
        },
      },
      {
        path: '/editInfo',
        component: () => import('@/views/user-center'),
        name: 'editInfo',
        meta: {
          title: '编辑信息',
        },
      },
      {
        path: '/activityDetail',
        component: () => import('@/views/major-business/form/ActivityDetailForm'),
        name: 'activityDetail',
        meta: {
          title: '活动营销详情',
        },
      },
      {
        path: '/newsDetail',
        component: () => import('@/components/CompanyNewsForm'),
        name: 'newsDetail',
        meta: {
          title: '公司新闻详情',
        },
      },
      {
        path: '/readerActivityDetail',
        component: () => import('@/views/reader-interaction/form/ReaderActiveForm'),
        name: 'readerActivityDetail',
        meta: {
          title: '读者互动详情',
        },
      },
      {
        path: '/bookDetail',
        component: () => import('@/views/major-business/form/BookDetailForm'),
        name: 'bookDetail',
        meta: {
          title: '图书出版详情',
        },
      },
      {
        path: '/announcementDetail',
        component: () => import('@/components/AnnouncementDetailForm'),
        name: 'announcementDetail',
        meta: {
          title: '通知公告详情',
        },
      },
      {
        path: '/swiper',
        component: () => import('@/components/swiper1'),
        name: 'swiper',
        meta: {
          title: '展示列表',
        },
      }
    ],
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  history.pushState(null, null, document.URL);
  next();
  if (to.path !== '/underBrand') {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }
});

export {
  router,
  routes
};
