// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import ajaxHelper from '../../utils/ajax/ajax-helper';

/**
 * 根据url请求数据，并将请求到的数据通过translator方法进行转换.
 * @param {String} url 请求地址
 * @param {Object} param 参数
 * @param {Function} translator 数据转换方法
 * @return Promise<any>
 */
const getDatas = async (url, param = {}) => {
  const { data: { data } } = await ajaxHelper.get(url, param);
  return data;
};

/**
 * 根据url请求数据， 可传对象、数组类型的参数
 * @param {String} url 请求地址
 * @param {Object} param 参数
 */
const getDatasObjectParam = async (url, param) => {
  const { data: { data } } = await ajaxHelper.get(url, param, {
    paramsSerializer: params => qs.stringify(params, { indices: false })
  });
  return data;
};

/**
 * 根据id查询数据
 * @param {String} id 主键
 * @param {String} url 请求接口地址
 */
const getById = (url, id) => getDatas(url, { id });

/**
 * 根据id删除数据
 * @param {Object} id 主键
 * @param {String} url 请求接口地址
 */
const deleteById = (url, id) => ajaxHelper.delete(url, id);

/**
 * 根据参数删除数据
 * @param {Object} param 参数
 * @param {String} url 请求接口地址
 */
const deleteByParam = (url, param) => ajaxHelper.delete(url, param);

/**
 * 新增数据
 * @param {Object} data 数据
 * @param {String} url 请求接口地址
 */
const postData = (url, data, config) => ajaxHelper.post(url, data, config);

/**
 * 编辑数据
 * @param {Object} data 数据
 * @param {String} url 请求接口地址
 * @param {Object} config axios配置信息
 */
const update = (url, data, config) => ajaxHelper.put(url, data, config);

/**
 * 查询列表
 * @param {Object} param 查询参数
 * @param {String} url 接口地址
 */
const list = (url, param) => getDatas(url, param);

/**
 * 下载
 * @param {Object} param 查询参数
 * @param {String} url 接口地址
 */
const download = (url, param) => ajaxHelper.get(url, param, { responseType: 'blob', timeout: 18000000 });

export {
  getDatas,
  getById,
  deleteById,
  deleteByParam,
  postData,
  update,
  list,
  getDatasObjectParam,
  download
};
