<template>
  <div class="pj-footer">
    <div class="footer-Box">
      <div class="footer-top">
        <div class="footer-img">
          <img :src="info.logo"  alt="" />
          <p>{{ info.name }}</p>
          <p>{{ info.address}}</p>
        </div>
        <div class="concat">
          <div class="concat-box">
            <div class="img-box"><img src="@/assets/image/footer_icon_telephone.png"  alt="" /></div>
            <p>咨询电话</p>
            <p>{{ info.phone }}</p>
          </div>
          <!-- <div class="concat-box">
            <div class="img-box"><img src="@/assets/image/footer_icon_facsimile.png"  alt="" /></div>
            <p>传真号码</p>
            <p>028-11001100</p>
          </div> -->
        </div>
        <div class="code-content">
          <div class="code-box">
            <img :src="info.weibo"  alt="" />
            <p>关注时代出版社官方微博</p>
          </div>
          <div class="code-box">
            <img :src="info.wechat"  alt="" />
            <p>关注时代出版社官方微信</p>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <span>Copyright © 1985-2023 成都时代出版社. All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023013694号-1</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from '@/api/servvice/CommonService';

export default {
  name: 'Footer',
  data() {
    return {
      info: {
        logo: '',
        name: '',
        address: '',
        phone: '',
        weibo: '',
        wechat: ''
      }
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const {
        logo,
        name,
        address,
        phone,
        weibo,
        wechat
      } = await CommonService.getEnterpriseInfo();
      this.info = {
        logo,
        name,
        address,
        phone,
        weibo,
        wechat
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.pj-footer {
  background-color: $-content-color-white;
  width: 100%;
  height: 273px;
  border-top: $-default-bold-border-orange;
  box-sizing: border-box;
  font-family: $-font-family-syht;
  .footer-Box {
    width: 1240px;
    height: 100%;
    margin-left: calc((100% - 1240px) / 2);
    .footer-top {
      width: 100%;
      height: 221px;
      display: flex;
      .footer-img {
        width: 288px;
        height: 100%;
        padding: 39px 0;
        margin-right: 400px;
        img {
          width: 266px;
          height: 80px;
        }
        p {
          line-height: 1;
          font-size: $-font-size-little;
          color: #131415;
          letter-spacing: 0em;
        }
      }
      .concat {
        width: 140px;
        height: 100%;
        display: flex;
        .concat-box {
          width: 110px;
          height: 100%;
          padding-top: 63px;
          text-align: center;
          .img-box {
            width: 52px;
            height: 52px;
            border: 1px solid #000000;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-left: 29px;
          }
          p {
            font-size: $-font-size-little;
            color: #131415;
          }
        }
        .concat-box:first-child {
          // margin-right: 10px;
        }
      }
      .code-content {
        width: 354px;
        height: 100%;
        margin-left: 27px;
        padding-top: 28px;
        display: flex;
        .code-box {
          width: 170px;
          height: 100%;
          p {
            width: 170px;
            font-size: $-font-size-little;
            color: #808080;
            line-height: 0;
          }
          img {
            width: 152px;
            height: 152px;
          }
        }
        .code-box:first-child {
          margin-right: 46px;
        }
      }
    }
    .footer-bottom {
      width: 100%;
      height: calc(100% - 221px);
      border-top: $-default-border-light-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $-font-size-little;
      color: $-font-color-bold-gray-x;
      a {
        color: $-font-color-bold-gray-x;
      }
    }
  }
}
</style>
