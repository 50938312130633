<template>
  <div class="pj-web-content">
    <web-top-bar
      @updateNavigation="updateNavigation"
      :navigationName="navigationName"
    ></web-top-bar>
    <div class="web-content-view">
      <transition name="fade-transform" mode="out-in">
        <!-- :style="`min-height: ${height}px !important`"  -->
        <router-view :key="key" />
      </transition>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import WebTopBar from '@/components/WebTopBar';
import Footer from '@/components/Footer';
import utilsMixins from '@/mixins/utilsMixins';

export default {
  name: 'Layout',
  mixins: [utilsMixins],
  components: { WebTopBar, Footer },
  computed: {
    key() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isShow: false,
      navigationName: '',
      height: 0
    };
  },
  methods: {
    // 控制导航折叠显示
    updateNavigation(val) {
      this.isShow = val;
    },
    // 关闭导航
    closeNavigation(name) {
      this.navigationName = name;
      this.isShow = false;
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/style/variables';
@import "@/style/var.scss";
.pj-web-content {
  position: relative;
  width: $web-weidth;
  min-width: $web-min-width;
  margin: auto;
}
// .web-content-view {
//   @include respond-to('phone') {
//     min-height: var(--theme-height) !important;
//   };
//   @include respond-to('pad') {
//     height: 1600px;
//   };
//   @include respond-to('notebook') {
//     height: 1200px;
//   };
//   @include respond-to('desktop') {
//     height: 1000px;
//   };
// }
</style>
