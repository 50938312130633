// 引入axios对象
import axios from 'axios';
import Vue from 'vue';

// 引入全局路由对象
// const router = require(`@/modules/${terminal}/router/index`).default;

// webpack 环境变量，构建时自动替换现网动态请求域名前缀
axios.defaults.baseURL = 'https://www.sdcbs.com:8081/';
// 设置请求超时时间
axios.defaults.timeout = 30000;

// axios请求拦截器 用以在请求时将token绑定到请求头上
axios.interceptors.request.use(
  (config) => {
    const { headers } = config;
    headers['Access-Control-Allow-Origin'] = '*';
    const token = sessionStorage.getItem('token');
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    // 如果请求方法为get则转码其中的中文参数
    if (config.method === 'get') {
      // 加入标识，防止ie浏览器下缓存
      // eslint-disable-next-line no-param-reassign, dot-notation
      config.params['_unique'] = (new Date()).getTime();
    }
    return config;
  },
  err => Promise.reject(err)
);

// axios响应拦截器 用以在响应时将token存入sessionStorage中
axios.interceptors.response.use(
  (response) => {
    const { data, config: { responseType = '' } } = response;
    // 如果返回类型是text/blob/arraybuffer，则直接返回response
    if (['text', 'blob', 'arraybuffer'].includes(responseType)) {
      return response;
    }
    // 判断result为false的时候，抛出异常
    if (data.status !== 'success') {
      Vue.prototype.$message({
        type: 'error',
        message: data.data || data.message
      });
      throw new Error(data.data || data.message);
    }
    return response;
  },
  (err) => {
    // 如果错误状态为401,清空token,跳转登录页
    // if (err.response?.status === 401) {
    //   setToken('');
    //   // 正式环境
    //   if (environment === 'prod') {
    //     window.open(ssoLoginPageURL, '_self');
    //   } else {
    //     // 非正式环境
    //     router.push(`/${process.env.VUE_APP_LOGIN_URL}`);
    //   }
    // }
    return Promise.reject(err);
  }
);

export default axios;
