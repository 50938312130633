import {
  navigation,
  loginPage,
  indexUrl,
  majorUrl,
  aboutShidaiUrl,
  consultationUpdatesUrl,
  readerInteractionUrl
} from '../url/CommonUrl';
import {
  getDatas,
  postData,
  update,
  download
} from './commonRequestApi';

class CommonService {
  static getNavigationList = () => getDatas(navigation.getNavigationList);

  static login = (data) => postData(loginPage.login, data);

  static logout = (data) => postData(loginPage.logout, data);

  static shortMessageLogin = (data) => postData(loginPage.shortMessageLogin, data);

  static register = (data) => postData(loginPage.register, data);

  static getCode = (data) => postData(loginPage.getCode, data);

  static myActivityList = (param) => getDatas(loginPage.myActivityList, param);

  static changePassword = (data) => update(loginPage.changePassword, data);

  static getUserAgreement = (data) => getDatas(loginPage.getUserAgreement, data);

  static indexSearch = (param) => getDatas(indexUrl.indexSearch, param);

  static getBannerList = () => getDatas(indexUrl.getBannerList);

  static getLatestNewsList = () => getDatas(indexUrl.getLatestNewsList);

  static getBrandList = () => getDatas(indexUrl.getBrandList);

  static getLatestNoticeList = () => getDatas(indexUrl.getLatestNoticeList);

  static getMainBusinessList = () => getDatas(indexUrl.getMainBusinessList);

  static getTypeList = () => getDatas(majorUrl.getTypeList);

  static getBookList = (param) => getDatas(majorUrl.getBookList, param);

  static getBookInfo = (param) => getDatas(majorUrl.getBookInfo, param);

  static getJournalList = () => getDatas(majorUrl.getJournalList);

  static getDigitList = () => getDatas(majorUrl.getDigitList);

  static getDigitItemList = (param) => getDatas(majorUrl.getDigitItemList, param);

  static getActivityList = (param) => getDatas(majorUrl.getActivityList, param);

  static getActivityInfo = (param) => getDatas(majorUrl.getActivityInfo, param);

  static getEnterpriseInfo = () => getDatas(aboutShidaiUrl.getEnterpriseInfo);

  static getHonorList = (param) => getDatas(`${aboutShidaiUrl.getHonorList}?type=${param.type}`);

  static getNewsList = (param) => getDatas(consultationUpdatesUrl.getNewsList, param);

  static getNewsInfo = (param) => getDatas(consultationUpdatesUrl.getNewsInfo, param);

  static getNoticeList = (param) => getDatas(consultationUpdatesUrl.getNoticeList, param);

  static getNoticeInfo = (param) => getDatas(consultationUpdatesUrl.getNoticeInfo, param);

  static downloadFile = (param) => download(consultationUpdatesUrl.downloadFile, param);

  static getCouponList = () => getDatas(readerInteractionUrl.getCouponList);

  static getReadActivityList = (param) => getDatas(readerInteractionUrl.getActivityList, param);

  static getReadInfo = (param) => getDatas(readerInteractionUrl.getReadInfo, param);

  static enterActivity = (data) => postData(readerInteractionUrl.enterActivity, data);

  static userFeedback = (data) => postData(readerInteractionUrl.userFeedback, data);
}

export default CommonService;
