<template>
  <div class="pj-topBar">
    <div class="pj-container">
      <img class="pj-img" :src="require('@/assets/image/footer_logo.png')" alt=""/>
      <div class="router-tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane
            v-for="(t, index) in tabsData"
            :key="index"
            :name="t.title"
            :label="t.title"
            :id="t.id"
          >
            <div
              @click="changePage(t)"
              @keydown="() => {}"
              slot="label"
            >
              {{ t.title }}
              <i
                class="el-icon-arrow-down"
                v-if="t.child && t.child.length > 0"
              ></i>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="search-content">
        <el-autocomplete
          prefix-icon="el-icon-search"
          class="inline-input"
          v-model="searchText"
          :fetch-suggestions="querySearch"
          placeholder="输入关键字"
          :trigger-on-focus="false"
          @select="(value) => handleSelect(value, 'search')"
        >
          <template slot-scope="{ item }">
            <div class="autocomplete-text">{{ item.text }}</div>
          </template>
        </el-autocomplete>
      </div>
      <div class="pj-login-btn" @click="toLogin" v-if="!name" @keydown="() => {}">
          <span class="link">登录</span>
          <span>/</span>
          <span class="link">注册</span>
      </div>
      <el-dropdown trigger="click" v-else>
        <div class="login-name">{{ `${name}，欢迎登录！`}}</div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="handleClick">个人中心</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-collapse-transition>
      <div class="navigation" v-if="isShow">
        <div class="navigation-box">
          <el-tabs
            tab-position="left"
            v-for="(item, index) in subTabsDatas"
            :key="index"
            v-model="subNav"
          >
            <el-tab-pane
              v-for="(t, i) in item"
              :key="i"
              :label="t.title"
              :name="t.title"
            >
              <div
                @click="subChangePage(t)"
                @keydown="() => {}"
                slot="label"
              >
                {{ t.title }}
                <i
                  class="el-icon-arrow-down"
                  v-if="t.child && t.child.length > 0"
                ></i>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import CommonService from '@/api/servvice/CommonService';
import { isEmpty, remove } from 'lodash';
import { sessionHandle } from '@/utils/session';
import { routes } from '@/router';
import eventBus from '@/utils/eventBus';

export default {
  name: 'web-top-bar',
  created() {
    eventBus.$on('changeSubNavigation', () => {
      this.$nextTick(() => {
        this.handleChangeSub();
      });
    });
    eventBus.$on('changeBothNav', () => {
      this.$nextTick(() => {
        this.handleChangeBoth();
      });
    });
    eventBus.$on('changeLoginStatus', () => {
      this.$nextTick(() => {
        this.name = null;
        this.activeName = '首页';
        this.subNav = '';
        document.getElementById('tab-首页').style.color = '#E73828';
        sessionHandle('set', 'activeName', '首页');
        sessionHandle('remove', 'subNavigationName');
      });
    });
    eventBus.$on('carouselClick', (data) => {
      this.$nextTick(() => {
        const obj = {
          ...data,
          type: data.link
        };
        this.handleSelect(obj, 'carouselClick');
      });
    });
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      isShow: false,
      name: sessionHandle('get', 'name') || null,
      activeName: sessionHandle('get', 'activeName') || '首页',
      subNavName: '',
      searchText: '',
      sign: '',
      tabsData: [],
      subTabsDatas: [],
      subNav: sessionHandle('get', 'subNavigationName') || '',
      routeData: routes[1]?.children ?? [],
      tabsAll: []
    };
  },
  watch: {
    subNav: {
      sync: true,
      handler(newVal) {
        // eslint-disable-next-line eqeqeq
        if (newVal != 0) {
          sessionHandle('remove', 'savePath');
          sessionHandle('set', 'subNavigationName', newVal);
        }
      },
      deep: true
    },
    activeName: {
      sync: true,
      handler() {
        sessionHandle('remove', 'savePath');
      },
      deep: true
    }
  },
  methods: {
    // 去登录
    toLogin() {
      sessionHandle('set', 'savePath', this.$route.fullPath);
      this.$router.replace('/login');
      // ['activeName', 'subNavigationName'].forEach((t) => {
      //   sessionHandle('remove', t);
      // });
    },
    // 搜索
    async querySearch(queryString, cb) {
      const data = await CommonService.indexSearch({ keyword: queryString });
      cb(data);
    },
    // 选中搜索内容
    handleSelect(item, type) {
      if (type === 'carouselClick' && item.link_type === 'none') {
        return;
      }
      if (item.link_type === 'outer') {
        window.open(item.link, '_blank');
        return;
      }
      const oneLevelData = this.tabsData.find((t) => t.link === item.type);
      if (oneLevelData) {
        this.changePage(oneLevelData, type, item);
      } else {
        const twoLevelData = this.tabsAll.find((t) => t.link === item.type);
        if (twoLevelData) {
          this.subNav = twoLevelData.title;
          this.subChangePage(twoLevelData, type, item);
        }
      }
    },
    // eventBus传递更改二级标题
    handleChangeSub() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.subNav = sessionHandle('get', 'subNavigationName');
        });
      });
    },
    // 改变一级和二级标题
    handleChangeBoth() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeName = sessionHandle('get', 'activeName');
          this.changeColor(this.activeName);
          this.subNav = sessionHandle('get', 'subNavigationName');
        });
      });
    },
    // 改变tabs颜色
    changeColor(val) {
      this.tabsData.forEach((t) => {
        document.getElementById(`tab-${t.title}`).style.color = val === t.title ? '#E73828' : '#333';
      });
    },
    // 获取tab数据
    async getData() {
      this.tabsData = await CommonService.getNavigationList();
      this.subTabsDatas = remove(this.tabsData.map((t) => t.child), (v) => !isEmpty(v)) ?? [];
      this.$nextTick(() => {
        setTimeout(() => {
          document.getElementById(`tab-${sessionHandle('get', 'activeName') || '首页'}`).style.color = '#E73828';
        });
      });
      const arr = [];
      this.tabsData.forEach((t) => {
        arr.push(t);
        if (!isEmpty(t.child)) {
          t.child.forEach((v) => {
            arr.push(v);
          });
        }
      });
      this.tabsAll = arr;
    },
    // 点击下拉
    handleClick() {
      this.$route.name !== 'userCenter' && this.$router.push({ path: '/userCenter' });
    },
    // 一级标题跳转页面
    changePage(val, type, itemData) {
      if (isEmpty(val.child)) {
        this.subNav = '';
        sessionHandle('remove', 'subNavigationName');
        sessionHandle('set', 'activeName', val.title);
        this.changeColor(val.title);
        if (type === 'carouselClick') {
          const detailData = this.routeData.find((v) => v.meta.title === `${val}详情`);
          detailData.path && itemData.bannerable_id
            && this.$router.push({ path: detailData.path, query: { id: itemData.bannerable_id } });
        } else if (type === 'search') {
          const detailData = this.routeData.find((v) => v.meta.title === `${val}详情`);
          detailData.path && itemData.id
            && this.$router.push({ path: detailData.path, query: { id: itemData.id } });
        } else {
          (this.$route.name !== val.link) && this.$router.push({ path: val.link === 'index' ? val.link : '/readerInteraction' });
        }
        this.isShow = false;
      } else {
        this.isShow = !this.isShow;
      }
    },
    // 二级标题跳转页面
    subChangePage(val, type, itemData) {
      const data = this.tabsData.find((t) => t.id === val.parent_id);
      sessionHandle('set', 'activeName', data.title);
      this.changeColor(data.title);
      this.activeName = data.title;
      this.$nextTick(() => {
        setTimeout(() => {
          this.subNavChange(this.subNav, type, itemData);
        });
      });
    },
    // 当点击子菜单时跳转路由
    subNavChange(val, type, itemData) {
      this.$nextTick(() => {
        const data = this.routeData.find((t) => t.meta.title === val);
        if (data) {
          if (type === 'carouselClick') {
            const detailData = this.routeData.find((v) => v.meta.title === `${val}详情`);
            detailData.path && itemData.bannerable_id
              && this.$router.push({ path: detailData.path, query: { id: itemData.bannerable_id } });
          } else if (type === 'search') {
            const detailData = this.routeData.find((v) => v.meta.title === `${val}详情`);
            detailData.path && itemData.id
              && this.$router.push({ path: detailData.path, query: { id: itemData.id } });
          } else {
            this.$route.name !== data.name && this.$router.push({ path: data.path });
          }
        } else if (val === '版权服务') {
          const page = this.tabsAll.find((t) => t.title === val);
          if (page && !page.link) {
            this.$message.warning('暂无数据');
            return;
          }
          window.open(page.link, '_blank');
        } else {
          this.$router.push({ path: '/underBrand', query: { index: val } });
        }
        this.isShow = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '~@/assets/style/variables';
@import "@/style/var.scss";
.pj-topBar {
  width: 100%;
  height: 126px;
  padding: 27px 0;
  /deep/ .pj-container {
    position: relative;
    width: 1360px;
    margin-left: calc((100% - 1360px) / 2);
    height: 100%;
    display: flex;
    align-items: center;
    .pj-img {
      width: 238px;
      height: 72px;
      margin-right: 80px;
    }
    .search-content {
      width: 200px;
      margin-right: 10px;
      .el-input__inner {
        width: 100%;
        height: 40px;
        border-radius: 58px;
        border: 1px solid #DDDDDD;
      }
    }
    .pj-login-btn {
      width: 96px;
      height: 40px;
      line-height: 40px;
      border-radius: 20px;
      background-color: #F4F4F4;
      font-size: $-font-size-little;
      color: $-content-color-orange;
      text-align: center;
      .link {
        cursor: pointer;
        font-family: $-font-family-syht;
      }
    }
    .router-tabs {
      margin-right: 60px;
      .el-tabs__header {
        margin: 0;
      }
      .el-icon-arrow-down:before {
        color: $-font-color-bold-gray-x;
        font-size: 12px;
      }
      .el-tabs--card>.el-tabs__header .el-tabs__nav,
      .el-tabs--card>.el-tabs__header,
      .el-tabs--card>.el-tabs__header .el-tabs__item {
        border: none;
      }
      .el-tabs__item {
        padding: 0;
        margin: 0 12px;
        font-size: $-font-size-big;
        color: $-font-color-bold-black-xx;
        font-family: $-font-family-syht;
        letter-spacing: 0em;
      }
      .el-tabs__item.is-active {
        // color: $-font-color-orange;
      }
      .el-tabs__item:first-child::before {
        display: none;
      }
      .el-tabs__nav-wrap::after, .el-tabs__active-bar {
        display: none;
      }
    }
  }
}
.login-name {
  cursor: pointer;
  width: 140px;
  text-align: center;
  font-size: $-font-size-little;
  color: $-content-color-orange;
  font-family: $-font-family-syht;
}
::v-deep .navigation {
  width: 100%;
  min-width: $web-min-width;
  border-radius: 0px 0px 22px 22px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 5px 0px rgba(234, 54, 28, 0.2);
  border-top: 1px solid rgba(234, 54, 28, 0.1);
  position: absolute;
  top: 126px;
  z-index: 999;
  .navigation-box {
    display: flex;
    justify-content: center;
    padding-right: 120px;
    .el-tabs__item {
      padding: 0;
      height: 34px;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: $-font-size-small;
      color: $-font-color-bold-black-xx;
      font-family: $-font-family-syht;
      letter-spacing: 0em;
      margin: 0 20px 12px 30px;
    }
    .el-tabs__item.is-active {
      color: $-font-color-orange;
      background: linear-gradient(270deg, rgba(255,235,234,0.00) 0%, #FFEBEA 48%, rgba(255,235,234,0.00) 100%);
    }
    .el-tabs__item:first-child::before {
      display: none;
    }
    .el-tabs__nav-wrap::after, .el-tabs__active-bar {
      display: none;
    }
  }
}
.autocomplete-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
