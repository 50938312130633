import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import ElementUI from 'element-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'element-ui/lib/theme-chalk/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'swiper/dist/css/swiper.css';
import App from './App';
import { router } from './router';
import store from './store';
import './assets/style/base.scss';

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

Vue.use(ElementUI);

history.pushState(null, null, document.URL);

window.addEventListener('popstate', () => {
  // eslint-disable-next-line no-restricted-globals
  history.pushState(null, null, document.URL);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
